import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  booking_details,
  booking_id,
  package_common_details,
  package_path,
  updateRedux,
} from "../../../../redux/commonReducer";
import { getBookingDetails } from "../../../../redux/commonActionCreator";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatTwoDigits } from "../../../../utils/helpers";
import { IMAGE_BASE_URL } from "../../../../constants/configuration";

export default function Step4() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useSelector(booking_id);
  const finalBookingId = localStorage.getItem("final_booking_id");
  // const id = "6731b666ad68ed5cf9e6a3c4";
  const details = useSelector(booking_details);
  const package__details = useSelector(package_common_details)
  const flightBooking = localStorage.getItem("flight_booking");

  useEffect(() => {
    if (finalBookingId?.length) {
      dispatch(getBookingDetails(finalBookingId));
      localStorage.removeItem("searchData");
      localStorage.removeItem("searchGuestData");
      localStorage.removeItem("package_details");
      localStorage.removeItem("selected_flight");
      localStorage.removeItem("otp_verified");
      localStorage.removeItem("final_data");
      localStorage.removeItem("package_path");
      localStorage.removeItem("book_now_loader");
      localStorage.removeItem("travelerData");
      dispatch(updateRedux({ key: "otp_verified", value: false }));
      dispatch(updateRedux({ key: "promo_code_applied", value: false }));
    }
  }, [finalBookingId]);

  // const next = [
  //   {
  //     img: "/assets/img/icons/start1.svg",
  //     title: "We're Crafting Your Dream Itinerary",
  //     desc: "Our team will reach out soon to discuss your travel preferences and create a personalized plan just for you.",
  //   },
  //   {
  //     img: "/assets/img/icons/start2.svg",
  //     title: "Manage Your Booking",
  //     desc: "In the meantime, you can log in to the website using your token reference number to view and manage your booking details.",
  //   },
  //   {
  //     img: "/assets/img/icons/start3.svg",
  //     title: "Personalized Quote",
  //     desc: "Once your itinerary is finalized, we'll send you a detailed quote for your review and confirmation.",
  //   },
  //   {
  //     img: "/assets/img/icons/start4.svg",
  //     title: "Explore with Our App",
  //     desc: "Upon booking confirmation, gain access to our mobile app your one-stop shop for all your travel documents and essential information",
  //   },
  // ];
  return (
    <div className="payment__success">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <img
                    src="/assets/img/payment-success.png"
                    alt=""
                    className="main w-100"
                  />
                </div>
                <div className="col-md-7">
                  <div className="message">
                    <h2>Payment Successful!</h2>
                    <p>
                      Your payment of{" "}
                      <span>{details?.bookingpayments?.amount} USD</span> was
                      successful! You will receive an email confirming your
                      reservation details shortly!
                    </p>
                    <div className="booking">
                      <div className="bookingID">
                        Booking ID:{details?.booking_ref_id}
                      </div>
                      <p>
                        {`${moment(details?.created_date).format(
                          "DD MMMM YYYY"
                        )} | ${moment(details?.created_date).format("h:mm a")}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment__details">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 rightBorder">
                    <h4>Token Payment Details</h4>
                    <div className="detailGrid">
                      <div className="item">
                        <h6>
                          Token Advance{" "}
                          <span>{details?.bookingpayments?.amount} USD</span>
                        </h6>
                        {/* <h6>
                          VAT 5% <span>15</span>
                        </h6> */}
                        <h6>
                          Total Paid{" "}
                          <span>{details?.bookingpayments?.amount} USD</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h4>Package Details</h4>
                    <div className="detailGrid">
                      <div className="item">
                        <h5>
                          Package Name{" "}
                          <span>{details?.bookingitinerary?.package_name}</span>
                        </h5>
                        <h5>
                          Duration <span>{details?.travel_duration === 1 ? "Day" :"Days"}</span>
                        </h5>
                        <h5>
                          Total Number of traveller{" "}
                          <span>
                            {formatTwoDigits(details?.no_of_travellers ?? 1)}
                          </span>
                        </h5>
                        <h5>
                          Adult{" "}
                          <span>
                            {formatTwoDigits(details?.no_of_adults ?? 1)}
                          </span>
                        </h5>
                        <h5>
                          Children{" "}
                          <span>
                            {formatTwoDigits(details?.no_of_childrens ?? 0)}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment__details">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 rightBorder p-0">
                    <h4>Main Traveller Details</h4>
                    <div className="detailGrid gridInline">
                      <div className="item">
                        <h6 className="mb-1">First Name</h6>
                        <span>{details?.traveller_info?.[0]?.first_name}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Middle Name</h6>
                        <span>{details?.traveller_info?.[0]?.middle_name}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Last Name</h6>
                        <span>{details?.traveller_info?.[0]?.last_name}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Email</h6>
                        <span>{details?.traveller_info?.[0]?.email}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Phone Number</h6>
                        <span>{`${details?.traveller_info?.[0]?.country_code} ${details?.traveller_info?.[0]?.phone}`}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Nationality</h6>
                        <span>{details?.traveller_info?.[0]?.nationality}</span>
                      </div>
                      <div className="item">
                        <h6 className="mb-1">Birth Date</h6>
                        {/* <span>{details?.traveller_info?.[0]?.dob}</span> */}
                        <span>
                          {moment(
                            details?.traveller_info?.[0]?.dob,
                            "DD-MM-YYYY"
                          ).format("D MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="anticipated__cost">
                      <div className="item">
                        <h2>ANTICIPATED COST</h2>
                        <h3>
                          {details?.bookingitinerary?.adult_cost?.toFixed(2)}{" "}
                          <span>USD X Per Person</span>
                        </h3>
                        <p>
                          total of{" "}
                          {details?.bookingitinerary?.total_estimate?.toFixed(
                            2
                          )}{" "}
                          USD
                        </p>
                      </div>
                    </div>
                    <div className="login">
                      <p>Please login to manage the Additional Details</p>
                      <button
                        className="btn-login"
                        onClick={() => {
                          localStorage.removeItem("token");
                          localStorage.removeItem("csrf");
                          localStorage.removeItem("booking_id");
                          localStorage.removeItem("booking_details");
                          navigate("/login");
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  {flightBooking === "true" &&
                  details?.flight_outbound?.aircraft_details?.length ? (
                    <div className="col-md-6 position-relative">
                      <h4>Flight Details</h4>
                      <div className="detailGrid">
                        <div className="item">
                          <h5>
                            Chosen Flights{" "}
                            <span>
                              {
                                details?.flight_outbound?.aircraft_details?.[0]
                                  ?.name
                              }
                            </span>
                          </h5>
                          <h5>
                            Point of Origin{" "}
                            <span>
                              {details?.flight_outbound?.departure_airport}
                            </span>
                          </h5>
                          <h5>
                            Traveling to{" "}
                            <span>
                              {details?.flight_outbound?.arrival_airport}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="text-right">
                        <img
                          src="/assets/img/flight-img.png"
                          className="flight"
                          alt=""
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6 position-relative">
                      <div className="text-right flightBg">
                        <img
                          src="/assets/img/flight-img.png"
                          className="flight"
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="card">
          <div className="card-body">
            <div className="what__next">
              <h3>Whats Next?</h3>
              <div className="row">
                {package__details?.what_is_next?.map((ele, i) => {
                  return (
                    <div className="col-md-3" key={i}>
                      <div className="nextGrid">
                        <img src={`${IMAGE_BASE_URL}cms/${ele.icon}`}  alt = {ele?.icon_alt}/>
                        <h2>{ele?.title}</h2>
                        <p>{ele?.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
