// dev
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
//   ? process.env.REACT_APP_API_BASE_URL
//   : "https://dev-api.skywardxperia.com/api/v1/";

export const IMAGE_BASE_URL = "https://skywardxperia.b-cdn.net/uploads/";

  // export const CURRENT_URL = "https://dev-web.skywardxperia.com"

// pre-production
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://preprod-api.skywardxperia.com/api/v1/";
export const CURRENT_URL = "https://preprod-web.skywardxperia.com"