import React, { useEffect, useState } from "react";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { Link } from "react-router-dom";
import * as MainTab from "@radix-ui/react-tabs";
import { useSelector } from "react-redux";
import { searchData } from "../../../redux/commonReducer";

export default function Itinerary({ details, path }) {
  const data = useSelector(searchData);

  const [euroActiveKey, setEuroActiveKey] = useState(null);
  const [nonEuroActiveKey, setNonEuroActiveKey] = useState(null);
  const [activeKey, setActiveKey] = useState("tab1");

  useEffect(() => {
    if (data?.departure?.length) {
      if (data.departure[0]?.is_oneday_travel) {
        setActiveKey("tab1");
      } else {
        setActiveKey("tab2");
      }
    } else {
      setActiveKey("tab1");
    }
  }, [data]);

  const handleEuroAccordionClick = (i, ele) => {
    setEuroActiveKey(euroActiveKey === i ? null : i);
  };

  const handleNonEuroAccordionClick = (i, ele) => {
    setNonEuroActiveKey(nonEuroActiveKey === i ? null : i);
  };

  return (
    <div className="itinerary">
      <div className="container">
        <h2 className="title">ITINERARY</h2>
        <img
          src={`${IMAGE_BASE_URL}${path}/${details?.main_map}`}
          alt={details?.main_map_alt}
          className="w-100 main"
        />

        <MainTab.Root className="TabsRoot" defaultValue={activeKey}>
          <MainTab.List className="TabsList" aria-label="Manage">
            {data?.departure?.length ? (
              <MainTab.Trigger className="TabsTrigger" value={activeKey}>
                {data.departure[0]?.is_oneday_travel
                  ? "European Itinerary"
                  : "Non-European Itinerary"}
              </MainTab.Trigger>
            ) : (
              <>
                <MainTab.Trigger className="TabsTrigger" value="tab1">
                  European Itinerary
                </MainTab.Trigger>
                <MainTab.Trigger className="TabsTrigger" value="tab2">
                  Non-European Itinerary
                </MainTab.Trigger>
              </>
            )}
          </MainTab.List>
          <MainTab.Content className="TabsContent" value="tab1">
            <div className="trip_schedules">
              <Accordion activeKey={euroActiveKey}>
                {(activeKey === "tab1"
                  ? details?.itinerary
                  : details?.nonEuropean_itinerary
                )?.map((ele, i) => {
                  return (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header onClick={() => handleEuroAccordionClick(i, ele)}>
                        Day {i + 1} :{ele?.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="collapse__content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ele?.description,
                            }}
                          ></p>


                          {i !== 0 && (
                            <Tabs id="controlled-tab-example" className="mb-3">
                              {ele?.attraction?.length ? (
                                <Tab eventKey="home" title="Attractions">
                                  <div className="attraction bg-transparent pb-0 pt-3">
                                    <div className="container">
                                      <div className="row">
                                        {ele?.attraction?.map((ele, i) => {
                                          return (
                                            <div className="col-md-3" key={i}>
                                              <Link to={`/attraction-details/${ele?.handle_key}`}>
                                                <div className="atraction">
                                                  <div className="image">
                                                    <img
                                                      src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                                      alt={ele.image_alt}
                                                      className="w-100"
                                                    />
                                                  </div>
                                                  <div className="contents">
                                                    <h3>{ele?.title}</h3>
                                                    <p
                                                      className="attract"
                                                      dangerouslySetInnerHTML={{
                                                        __html: ele?.short_description,
                                                      }}
                                                    ></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              ) : null}
                              {ele?.activity?.length ? (
                                <Tab eventKey="profile" title="Activities">
                                  <div className="attraction bg-transparent pb-0 pt-3">
                                    <div className="container">
                                      <div className="row">
                                        {ele?.activity?.map((ele, i) => {
                                          return (
                                            <div className="col-md-3" key={i}>
                                              <Link to={`/activity-details/${ele?.handle_key}`}>
                                                <div className="atraction">
                                                  <div className="image">
                                                    <img
                                                      src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                                      alt={ele.image_alt}
                                                      className="w-100"
                                                    />
                                                  </div>
                                                  <div className="contents">
                                                    <h3>{ele?.title}</h3>
                                                    <p className="itineraryDurationIcon">
                                                      <img
                                                        src="/assets/img/icons/clock.svg"
                                                        height={17}
                                                        alt=""
                                                      />
                                                      Duration {ele?.duration} hours
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              ) : null}
                              <Tab eventKey="contact" title="View Map">
                                <img
                                  src={`${IMAGE_BASE_URL}package/${ele?.map_image}`}
                                  alt={ele?.map_image_alt}
                                  className="w-100"
                                  style={{
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                              </Tab>
                            </Tabs>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </MainTab.Content>

          <MainTab.Content className="TabsContent" value="tab2">
            <div className="trip_schedules">
              <Accordion activeKey={nonEuroActiveKey}>
                {details?.nonEuropean_itinerary?.map((ele, i) => {
                  return (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header
                        onClick={() => handleNonEuroAccordionClick(i, ele)}
                      >
                        Day {i + 1} :{ele?.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="collapse__content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ele?.description,
                            }}
                          ></p>
                          {i !== 0 && i !== details?.nonEuropean_itinerary?.length - 1 && (
                            <Tabs id="controlled-tab-example" className="mb-3">
                              {ele?.attraction?.length ? (
                                <Tab eventKey="home" title="Attractions">
                                  <div className="attraction bg-transparent pb-0 pt-3">
                                    <div className="container">
                                      <div className="row">
                                        {ele?.attraction?.map((ele, i) => {
                                          return (
                                            <div className="col-md-3" key={i}>
                                              <Link
                                                to={`/attraction-details/${ele?.handle_key}`}
                                              >
                                                <div className="atraction">
                                                  <div className="image">
                                                    <img
                                                      src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                                      alt={ele.image_alt}
                                                      className="w-100"
                                                    />
                                                  </div>
                                                  <div className="contents">
                                                    <h3>{ele?.title}</h3>
                                                    <p
                                                      className="attract"
                                                      dangerouslySetInnerHTML={{
                                                        __html: ele?.short_description,
                                                      }}
                                                    ></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              ) : null}
                              {ele?.activity?.length ? (
                                <Tab eventKey="profile" title="Activities">
                                  <div className="attraction bg-transparent pb-0 pt-3">
                                    <div className="container">
                                      <div className="row">
                                        {ele?.activity?.map((ele, i) => {
                                          return (
                                            <div className="col-md-3" key={i}>
                                              <Link
                                                to={`/activity-details/${ele?.handle_key}`}
                                              >
                                                <div className="atraction">
                                                  <div className="image">
                                                    <img
                                                      src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                                      alt={ele.image_alt}
                                                      className="w-100"
                                                    />
                                                  </div>
                                                  <div className="contents">
                                                    <h3>{ele?.title}</h3>
                                                    <p className="itineraryDurationIcon">
                                                      <img
                                                        src="/assets/img/icons/clock.svg"
                                                        height={17}
                                                        alt=""
                                                      />
                                                      Duration {ele?.duration} hours
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              ) : null}
                              <Tab eventKey="contact" title="View Map">
                                <img
                                  src={`${IMAGE_BASE_URL}package/${ele?.map_image}`}
                                  alt={ele?.map_image_alt}
                                  className="w-100"
                                  style={{
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                              </Tab>
                            </Tabs>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </MainTab.Content>

        </MainTab.Root>
      </div>
    </div>
  );
}
