import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { zodResolver } from "@hookform/resolvers/zod";

import { curated, curated_gallery } from "../../components/web/config";
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import Title from "../../components/web/Title";
import {
  countryDetails,
  fetchCuratedData,
  submitEnquiryRequest,
} from "../../service/service";
import { curatedSchema } from "../../validation/validation";

import Fancybox from "../../components/web/Fancybox";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import {
  currency_data,
  endLoading,
  startLoading,
} from "../../redux/commonReducer";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { CounterItem } from "../../components/web/HomeSearch";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

export default function Curated() {
  const [guestOpen, setGuestOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [countryCode, setCountryCode] = useState();
  const [curatedData, setCuratedData] = useState({});
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const loader = useSelector((state) => state?.commonReducer?.isLoading);

  const {
    register,
    setValue,
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(curatedSchema),
    defaultValues: {
      guestData: [
        {
          title: "Adults",
          ageRange: "Ages 12 or above",
          key: "adults",
          count: 1,
        },
        { title: "Children", ageRange: "Ages 5–12", key: "age_5_12", count: 0 },
        { title: "Children", ageRange: "Ages 2–5", key: "age_2_5", count: 0 },
        { title: "Infants", ageRange: "Under 2", key: "age_less_2", count: 0 },
      ],
    },
  });

  // useEffect(() => {
  //   const total = guestData.reduce((sum, item) => sum + item.count, 0);
  //   setTotalGuests(total);
  // }, [guestData]);

  useEffect(() => {
    countryDetails((success, data) => {
      if (success && data) {
        setCountryCode(data);
      }
    });
  }, []);

  useEffect(() => {
    fetchCuratedData((success, data) => {
      if (success) {
        console.log(data);
        setCuratedData(data);
      }
    });
  }, []);

  const handleDropDown = () => {
    setGuestOpen(!guestOpen);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setGuestOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onCountChangeHandler = (type, item, index) => {
    const currentGuestData = watch("guestData");
    let updatedGuestData = [...currentGuestData];

    updatedGuestData[index] = {
      ...updatedGuestData[index],
      count:
        type === "add"
          ? updatedGuestData[index].count + 1
          : updatedGuestData[index].count > 0
          ? updatedGuestData[index].count - 1
          : 0,
    };

    setValue("guestData", updatedGuestData, { shouldValidate: true });
  };

  const onSubmit = async (data) => {
    let enquiryData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      country_code: data.country_code?.value,
      start_travel_date: data.travel_date.start_date,
      end_travel_date: data.travel_date.end_date,
      no_of_guest: data.guestData,
      enquiry_type: "curated",
      comment: data.comment,
      point_of_origin: data.point_of_origin,
    };

    dispatch(startLoading());
    submitEnquiryRequest(enquiryData, (res) => {
      if (res.status) {
        dispatch(endLoading());
        reset();
        setDateRange([null, null]);
        reset({
          country_code: null,
          guestData: [
            { title: "Adults", ageRange: "Ages 12 or above", key: "adults", count: 1 },
            { title: "Children", ageRange: "Ages 5–12", key: "age_5_12", count: 0 },
            { title: "Children", ageRange: "Ages 2–5", key: "age_2_5", count: 0 },
            { title: "Infants", ageRange: "Under 2", key: "age_less_2", count: 0 },
          ],
        });
        toast.success(res.message);
      } else {
        dispatch(endLoading());
        toast.error(res.message || "Submission failed");
      }
    });
  };

  const galleryArray = curatedData?.gallery || [];
  const ITEMS_PER_ROW = 3;
  const nestedGallery = [];

  for (let i = 0; i < galleryArray.length; i += ITEMS_PER_ROW) {
    nestedGallery.push(galleryArray.slice(i, i + ITEMS_PER_ROW));
  }

  const getThumbnail = (url) => {
    const videoId = url.split("/").pop();
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  return (
    <section className="curated__tours pt-0">
      <MetaHeader
        title={curatedData?.meta_title}
        description={curatedData?.meta_description}
        keywords={curatedData?.meta_keyword}
      />
      <div className="slider-container">
      <Slider {...curated}>
  {curatedData?.banner1 && (
    <div className="image_slide">
      <img
        src={`${IMAGE_BASE_URL}curated/${curatedData.banner1}`}
        alt={curatedData.banner1_alt || "Banner 1"}
      />
    </div>
  )}
  {curatedData?.banner2 && (
    <div className="image_slide">
      <img
        src={`${IMAGE_BASE_URL}curated/${curatedData.banner2}`}
        alt={curatedData.banner2_alt || "Banner 2"}
      />
    </div>
  )}
  {curatedData?.banner3 && (
    <div className="image_slide">
      <img
        src={`${IMAGE_BASE_URL}curated/${curatedData.banner3}`}
        alt={curatedData.banner3_alt || "Banner 3"}
      />
    </div>
  )}
</Slider>

      </div>
      <div className="curated_title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Title title={curatedData.main_title} />
              <p data-aos="fade-up" data-aos-duration="3000">
                {curatedData.main_tagline}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="package_contents">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 data-aos="fade-up" data-aos-duration="1000">
                {curatedData.sub_title}
              </h2>
            </div>
            <div className="col-md-6">
              <p data-aos="fade-up" data-aos-duration="2000">
                {" "}
                {curatedData.short_description}
              </p>
            </div>
            <div className="col-md-12 mt-5">
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
                dangerouslySetInnerHTML={{
                  __html: curatedData.long_description,
                }}
              ></p>
            </div>
          </div>
        </div>

        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <div className="curated_gallery">
            <Slider {...curated_gallery}>
              {nestedGallery?.map((group, i) => (
                <div key={i} className="slide-item">
                  <div className="slider__images">
                    {group.map((ele, i) => (
                      <div key={i} className="slide-content">
                        {ele?.type === "video" ? (
                          <div
                            className="video"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <a data-fancybox="gallery" href={ele.image}>
                              <div className="video-container">
                                <img
                                  src={getThumbnail(ele.image)}
                                  alt={ele.alt}
                                />
                                <div className="play">
                                  <img
                                    src="/assets/img/icons/play.svg"
                                    alt="Play"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>
                        ) : (
                          <div
                            className="image"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            <a
                              data-fancybox="gallery"
                              href={
                                ele?.image
                                  ? `${IMAGE_BASE_URL}curated/${ele.image}`
                                  : ""
                              }
                            >
                              <img
                                src={
                                  ele?.image
                                    ? `${IMAGE_BASE_URL}curated/${ele.image}`
                                    : ""
                                }
                                alt={ele.alt}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Fancybox>
      </div>

      <div className="container">
        <div className="get_in_touch">
          <Title title={curatedData?.getin_touch_title} />
          <div className="row gap-20">
            <div className="col-md-6">
              <img
                src={
                  curatedData?.getin_touch_image
                    ? `${IMAGE_BASE_URL}curated/${curatedData?.getin_touch_image}`
                    : ""
                }
                className="main w-100"
                alt={curatedData.getin_touch_image_alt}
              />
            </div>
            <div className="col-md-6">
              <form onSubmit={handleSubmit(onSubmit)} className="contents">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("name")}
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    placeholder="Enter Your Email Address"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    {...register("email")}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
                <div className="dates__guests">
                  <div className="county_list form-group">
                    <label htmlFor="countryCode">Country Code</label>
                    <Controller
                      name="country_code"
                      control={control}
                      rules={{ required: "Country code is required" }}
                      render={({ field }) => (
                        <div>
                          <Select
                            {...field}
                            options={
                              countryCode
                                ? countryCode?.map((item) => ({
                                    label: item?.dial_code,
                                    value: item?.dial_code,
                                  }))
                                : ""
                            }
                            placeholder="Code"
                            isClearable
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption || null);
                            }}
                            value={field.value || null}
                          />
                        </div>
                      )}
                    />

                    {errors.country_code && (
                      <span className="text-danger">
                        {errors.country_code.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="number"
                      placeholder="Enter Your Phone Number"
                      className={`form-control ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      {...register("phone")}
                    />
                    {errors.phone && (
                      <span className="text-danger">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="dates__guests">
                  <div className="form-group">
                    <label htmlFor="travel-dates">Travel Dates</label>
                    <div className="withIcon">
                      <Controller
                        name="travel_date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Select Travel dates"
                            onChange={(update) => {
                              setDateRange(update);
                              field.onChange({
                                start_date: update[0]
                                  ? update[0].toLocaleDateString("en-US")
                                  : "",
                                end_date: update[1]
                                  ? update[1].toLocaleDateString("en-US")
                                  : "",
                              });
                            }}
                            className={`form-control w-100 ${
                              errors.travel_date ? "is-invalid" : ""
                            }`}
                            dateFormat="dd/MM/yyyy"
                            minDate={
                              new Date(
                                new Date().setMonth(new Date().getMonth() + 1)
                              )
                            }
                            maxDate={
                              startDate
                                ? new Date(
                                    new Date(startDate).setMonth(
                                      new Date(startDate).getMonth() + 1
                                    )
                                  )
                                : undefined
                            }
                          />
                        )}
                      />
                      <img src="/assets/img/icons/date.svg" alt="" />
                    </div>
                    {errors.travel_date && (
                      <span className="text-danger">
                        {errors.travel_date.message}
                      </span>
                    )}
                    {errors.travel_date?.start_date && (
                      <span className="text-danger me-2">
                        {errors.travel_date.start_date.message}
                      </span>
                    )}
                    {errors.travel_date?.end_date && (
                      <span className="text-danger">
                        {errors.travel_date.end_date.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group" ref={dropdownRef}>
                    <label htmlFor="guests">No of Guests</label>
                    <div className="withIcon">
                      <input
                        onClick={handleDropDown}
                        type="text"
                        placeholder="No of Guests"
                        className="form-control"
                        value={watch("guestData").reduce(
                          (sum, item) => sum + item.count,
                          0
                        )}
                        readOnly
                      />
                      <img
                        onClick={handleDropDown}
                        src="/assets/img/icons/dropdown.svg"
                        style={{ right: "15px", width: "15px" }}
                        alt="Dropdown Icon"
                      />
                    </div>
                    {errors.guestData && (
                      <span className="text-danger">
                        {errors.guestData.message}
                      </span>
                    )}
                    {guestOpen && (
                      <div className="curated__test">
                        <div className="filterGrid">
                          <div className="guests">
                            {watch("guestData").map((item, index) => (
                              <CounterItem
                                key={index}
                                index={index}
                                item={item}
                                guestData={watch("guestData")}
                                onCountChangeHandler={onCountChangeHandler}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="origin">Point of Origin</label>
                  <input
                    type="text"
                    placeholder="Select Department From"
                    className={`form-control ${
                      errors.point_of_origin ? "is-invalid" : ""
                    }`}
                    {...register("point_of_origin")}
                  />
                  {errors.point_of_origin && (
                    <span className="text-danger">
                      {errors.point_of_origin.message}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="comment">Comments</label>
                  <textarea
                    placeholder="Your Comments"
                    className={`form-control ${
                      errors.comment ? "is-invalid" : ""
                    }`}
                    {...register("comment")}
                    rows={3}
                  ></textarea>
                  {errors.comment && (
                    <span className="text-danger">
                      {errors.comment.message}
                    </span>
                  )}
                </div>
                <div className="form-group right-align">
                  <button className="btn btn-primary">
                    {loader ? (
                      <Spinner animation="border" />
                    ) : (
                      <>
                        SEND{" "}
                        <img src="assets/img/icons/button-arrow.svg" alt="" />
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
