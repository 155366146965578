import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useWizard } from "rhf-wizard";
import {
  book_now_loader,
  currencyValue,
  flight_list,
  flightSearchLoader,
  updateRedux,
} from "../../../../redux/commonReducer";
import PackageDetailsSection from "./PackageDetailsSection";
import moment from "moment";
import { getRoundTripFlights } from "../../../../redux/commonActionCreator";
import { CurrencyConversion } from "../../../../utils/helpers";

export default function Step2({}) {
  const { goToNextStep, goToPreviousStep } = useWizard();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flightBooking = localStorage.getItem("flight_booking");
  const localData = JSON.parse(localStorage.getItem("searchData"));
  const LocalGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const bookNowLoader = localStorage.getItem("book_now_loader");

  const flightList = useSelector(flight_list);
  const loader = useSelector(flightSearchLoader);
  const selectedCurrency = useSelector(currencyValue);

  const [selectedFlight, setSelectedFlight] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const details = JSON.parse(localStorage.getItem("package_details"));

  useEffect(() => {
    if (bookNowLoader != "true") {
      if (flightBooking === "true") {
        searchFlight(localData, LocalGuestData);
      }
    } else {
      goToNextStep();
    }
  }, [flightBooking, bookNowLoader]);

  const searchFlight = (dataValue, guestValue) => {
    let raw = {
      origin: dataValue?.departure?.[0]?.airport_code ?? "",
      trip_start: dataValue?.start_date
        ? moment(dataValue?.start_date).format("YYYY-MM-DD")
        : "",
      trip_return: dataValue?.end_date
        ? moment(dataValue?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestValue?.find((ele) => ele?.key === "adults")?.count,
        age_5_12: guestValue?.find((ele) => ele?.key === "age_5_12")?.count,
        age_2_5: guestValue?.find((ele) => ele?.key === "age_2_5")?.count,
        age_less_2: guestValue?.find((ele) => ele?.key === "age_less_2")?.count,
      },
      no_of_travellers: guestValue?.reduce(
        (sum, person) => sum + person.count,
        0
      ),
    };
    dispatch(getRoundTripFlights(raw));
  };

  const onSelect = (ele) => {
    setSelectedFlight(ele);
    setConfirmModal(true);
  };

  const handleClose = () => {
    setSelectedFlight("");
    setConfirmModal(false);
  };

  const onSelectFlight = () => {
    dispatch(
      updateRedux({
        key: "selected_flight",
        value: selectedFlight,
      })
    );
    localStorage.setItem("selected_flight", JSON.stringify(selectedFlight));
    goToNextStep();
    handleClose();
  };

  return (
    <div className="container">
      <div className="flight__tickets">
        {loader ? (
          <div className="detail__loader">
            {/* <Spinner animation="border" /> */}
            <div className="left">
              {[0, 1, 2, 3]?.map((ele) => {
                return (
                  <>
                    <div className="trip_details"></div>
                  </>
                );
              })}
            </div>
            <div className="trip_details right"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-8">
              {!flightList?.length > 0 && (
                <div className="no__flights">
                  <div className="card">
                    <div className="card-body">
                      <img src="/assets/img/icons/flight-icon.svg" alt="" />
                      <h5>
                        No flights found for the selected dates. Please choose
                        different dates or continue without flight.
                      </h5>
                      <button
                        type="button"
                        className="continueBtn"
                        onClick={() => navigate(`/package-details/${details?.handle_key}`)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="continueBtn"
                        onClick={() => goToNextStep()}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {flightList?.map((ele, i) => {
                return (
                  <div className="card" key={i}>
                    <div className="card-body">
                      <div className="card__header">
                        <h5>
                          Start:{" "}
                          {ele?.outbound?.origin_start_time?.date
                            ? moment(
                                ele?.outbound?.origin_start_time?.date
                              ).format("ddd, D MMM")
                            : moment(localData?.start_date).format(
                                "ddd, D MMM"
                              )}
                        </h5>
                        <p>{i == 0 ? "Recommended" : ""}</p>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            {ele?.outbound?.aircraft_details?.map((el, i) => {
                              return (
                                <div className="icon">
                                  <img src={el?.logo} alt="" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">
                                {ele?.outbound?.origin_start_time?.time}
                              </h4>
                              <h5 class="city">
                                {ele?.outbound?.departure_airport}
                              </h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">
                                {ele?.outbound?.duration}
                              </span>
                              <span class="plane-icon">
                                <img
                                  src="/assets/img/icons/aeroplane.svg"
                                  alt=""
                                />
                              </span>
                              <span class="dashed-line"></span>
                              <span class="stops">
                                {ele?.outbound?.stop_summary}
                              </span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">
                                {ele?.outbound?.reach_time?.time}
                              </h4>
                              <h5 class="city">
                                {ele?.outbound?.arrival_airport}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Departure & Return</p>
                          <p>
                            Departure :{" "}
                            <span>
                              {ele?.outbound?.aircraft_details
                                ?.map((el) => el?.name)
                                .join(", ")}
                            </span>{" "}
                          </p>
                          <p>
                            Return :{" "}
                            <span>
                              {ele?.return?.aircraft_details
                                ?.map((el) => el?.name)
                                .join(", ")}
                            </span>
                          </p>

                          {/* <div className="details">Flight Details</div> */}
                        </div>
                      </div>

                      <div className="card__header return">
                        <h5>
                          End :{" "}
                          {ele?.return?.reach_time?.date
                            ? moment(ele?.return?.reach_time?.date).format(
                                "ddd, D MMM"
                              )
                            : moment(localData?.end_date).format("ddd, D MMM")}
                        </h5>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            {ele?.return?.aircraft_details?.map((el, i) => {
                              return (
                                <div className="icon">
                                  <img src={el?.logo} alt="" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">
                                {ele?.return?.reach_time?.time}
                              </h4>
                              <h5 class="city">
                                {ele?.return?.arrival_airport}
                              </h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">
                                {ele?.return?.duration}
                              </span>
                              <span class="plane-icon return">
                                <img
                                  src="/assets/img/icons/aeroplane.svg"
                                  alt=""
                                />
                              </span>
                              <span class="dashed-line"></span>
                              <span class="stops">
                                {ele?.return?.stop_summary}
                              </span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">
                                {ele?.return?.origin_start_time?.time}
                              </h4>
                              <h5 class="city">
                                {ele?.return?.departure_airport}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Price per Person</p>
                          <div className="price">
                            <span className="d-flex gap-1">
                              <h3  className="amount">
                                {CurrencyConversion(
                                  selectedCurrency,
                                  ele?.total_price
                                ) ?? ele?.total_price}
                              </h3>
                              <span className="amount" >
                                {selectedCurrency?.currency_code ?? "USD"}
                              </span>
                            </span>
                          </div>
                          {/* <span className="d-flex gap-1">
                            <h1>
                              {" "}
                              {CurrencyConversion(
                                selectedCurrency,
                                ele?.total_price ?? 0
                              )}
                            </h1>{" "}
                            <h6 className="mt-2">
                              {" "}
                              {selectedCurrency?.currency_code ?? "USD"}
                            </h6>
                          </span> */}
                          <div
                            className="select_flight"
                            onClick={() => onSelect(ele)}
                          >
                            Select Flight
                            <img
                              src="/assets/img/icons/button-arrow.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="view_more">
    view more
    <img src="/assets/img/icons/btn-arrow-dark.svg" alt="" />
  </div> */}
            </div>
            <PackageDetailsSection />
          </div>
        )}
      </div>
      <Modal
        show={confirmModal}
        centered
        size="md"
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="common__modal">
            <div className="closeModal" onClick={handleClose}>
              <img src="assets/img/icons/modal-close.svg" alt="" />
            </div>
            <img src="/assets/img/icons/question.svg" alt="" className="icon" />
            <h2>Are you sure want to select this flight ?</h2>
            <div className="d-flex gap-2 justify-content-center">
              <Button variant="modal-secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="modal-primary" onClick={() => onSelectFlight()}>
                Yes
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
