import React from "react";
import { useNavigate } from "react-router-dom";

export default function Highlights({ details }) {
  const navigate = useNavigate();

  const navigateHandler = (ele) => {
    if (ele?.link_type == "attraction") {
      navigate(`/attraction-details/${ele?.link_item?.handle_key}`);
    } else {
      navigate(`/activity-details/${ele?.link_item?.handle_key}`);
    }
  };

  return (
    <div className="highlights">
      <div className="container">
        <div className="header">
          <h2 className="title">highlight</h2>
        </div>
        <div className="row">
          {details?.highlights?.map((ele, i) => {
            return (
              <div
                className={`col-md-6 ${ele?.link_item ? "pointer" : ""}`}
                key={i}
                onClick={() => (ele?.link_item ? navigateHandler(ele) : {})}
              >
                <div className="highlight_grid">
                  <h4>{ele?.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: ele?.description }}></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
