import React, { useEffect, useState } from "react";
import Title from "../../components/web/Title";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  choose_settings,
  testimonial_settings,
} from "../../components/web/config";
import Aos from "aos";
import "aos/dist/aos.css";
import { fetchHomeData } from "../../service/service";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../redux/commonActionCreator";
import PackageList from "./PackageList";
import {
  package_data,
  package_loader,
  updateRedux,
} from "../../redux/commonReducer";
import HomeSearch from "../../components/web/HomeSearch";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packageData = useSelector(package_data);
  const loader = useSelector(package_loader);

  const [homeData, setHomeData] = useState({});
  const [readMoreState, setReadMoreState] = useState({});

  const handleReadMore = (index) => {
    setReadMoreState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    let rawData = {
      page: 0,
      perPage: 4,
      api_type: "popular",
    };
    dispatch(getPackages(rawData));
  }, []);

  useEffect(() => {
    fetchHomeData((success, data) => {
      if (success && data) {
        setHomeData(data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      updateRedux({
        key: "searchData",
        value: {
          departure: "",
          start_date: "",
          end_date: "",
        },
      })
    );
    dispatch(
      updateRedux({
        key: "searchGuestData",
        value: [
          {
            title: "Adults",
            ageRange: "Ages 12 or above",
            key: "adults",
            count: 1,
          },
          {
            title: "Children",
            ageRange: "Ages 5–12",
            key: "age_5_12",
            count: 0,
          },
          { title: "Children", ageRange: "Ages 2–5", key: "age_2_5", count: 0 },
          {
            title: "Infants",
            ageRange: "Under 2",
            key: "age_less_2",
            count: 0,
          },
        ],
      })
    );
    dispatch(
      updateRedux({
        key: "flight_booking",
        value: true,
      })
    );
    localStorage.removeItem("searchData");
    localStorage.removeItem("searchGuestData");
    localStorage.removeItem("package_details");
    localStorage.removeItem("flight_booking");
    localStorage.removeItem("selected_flight");
    localStorage.removeItem("otp_verified");
    localStorage.removeItem("final_data");
    localStorage.removeItem("package_path");
    localStorage.removeItem("book_now_loader");
    localStorage.removeItem("final_booking_id");
    localStorage.removeItem("travelerData");
    dispatch(updateRedux({ key: "otp_verified", value: false }));
    dispatch(updateRedux({ key: "booking_completed", value: false }));
    dispatch(updateRedux({ key: "promo_code_applied", value: false }));
    dispatch(updateRedux({ key: "selected_flight", value: "" }));
    dispatch(updateRedux({ key: "prev_email", value: "" }));
    dispatch(
      updateRedux({
        key: "travelerData",
        value: {
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
          country_code: "",
          nationality: "",
          dob: "",
        },
      })
    );
  }, []);

  // TESTIMONIAL GRID

  const testimonialsArray = homeData?.reviews || [];
  const ITEMS_PER_ROW = 4;
  const nestedTestimonial = [];

  for (let i = 0; i < testimonialsArray.length; i += ITEMS_PER_ROW) {
    nestedTestimonial.push(testimonialsArray.slice(i, i + ITEMS_PER_ROW));
  }

  // Loader

  return (
    <>
      <MetaHeader
        title={homeData?.meta_title}
        description={homeData?.meta_description}
        keywords={homeData?.meta_keyword}
      />
      <section className="skyward__banner">
        <video
          muted
          autoPlay
          playsInline
          loop
          className="masked-video"
          src={
            homeData?.banner_video
              ? `${IMAGE_BASE_URL}home/${homeData.banner_video}`
              : ""
          }
          poster="assets/video/poster.png"
        />
        <div className="banner__title">
          <h1 className="stroke">{homeData.banner_text}</h1>
          <h1>{homeData.banner_text}</h1>
        </div>
        <HomeSearch />
      </section>
      <section className="skyward__features">
        <div className="container">
          <div className="row row-cols-6">
            {homeData.features?.map((ele, i) => {
              return (
                <div className="col pl-0" key={ele._id}>
                  <div className={`featuresList ${i === 5 ? "lastItem" : ""}`}>
                    <img
                      src={ele.icon ? `${IMAGE_BASE_URL}home/${ele.icon}` : ""}
                      alt=""
                    />
                    <p>{ele?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="skyward__about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about_us">
                <img
                  src={
                    homeData?.about_image
                      ? `${IMAGE_BASE_URL}home/${homeData.about_image}`
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_us">
                <h2 data-aos="fade-up" data-aos-duration="1000">
                  {homeData.about_title}
                </h2>
                <p data-aos="fade-up" data-aos-duration="2000">
                  {homeData.about_tagline}
                </p>
                <Link to="/about">
                  <button
                    className="btn btn-primary"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    EXPLORE{" "}
                    <img src="assets/img/icons/button-arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__packages">
        <Title
          id={"testing"}
          title={homeData.tagline_title}
          desc={homeData.tagline_description}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="packageGrid">
                <img
                  className="main"
                  src={
                    homeData.self_drive_image
                      ? `${IMAGE_BASE_URL}home/${homeData.self_drive_image}`
                      : ""
                  }
                  alt=""
                />
                <div className="content">
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    {homeData.self_drive_title}
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    {homeData.self_drive_description}
                  </p>
                  <button
                    className="btn btn-primary"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    onClick={() => navigate("/self-drive")}
                  >
                    EXPLORE{" "}
                    <img src="assets/img/icons/button-arrow.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__popular_packages pt-0">
        <div className="container">
          <Title
            title={homeData.package_title}
            desc={homeData.package_tagline}
          />
          <div className="row">
            <PackageList packageData={packageData?.slice(0, 4)} />
          </div>

          <div className="button_center">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/self-drive")}
            >
              VIEW MORE <img src="assets/img/icons/button-arrow.svg" alt="" />
            </button>
          </div>
        </div>
      </section>
      <section className="skyward__attractions">
        <div className="container">
          <Title
            color={"#fff"}
            title={homeData.attraction_title}
            desc={homeData.attraction_tagline}
          />
          <div className="row mt-5">
            {homeData.attractions?.map((ele, i) => {
              return (
                <div
                  className="col-md-3 col-tab-2"
                  data-aos="fade-up"
                  data-aos-duration={`${i}000`}
                  key={i}
                >
                  <Link to={`/attraction-details/${ele?.handle_key}`}>
                    <div className="atraction">
                      <div className="image">
                        <img
                          src={
                            ele.image
                              ? `${IMAGE_BASE_URL}attraction/${ele.image}`
                              : ""
                          }
                          alt=""
                        />
                      </div>
                      <div className="contents">
                        <h3>{ele?.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: ele?.short_description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="skyward__whyChoose_us">
        <Title
          title={homeData.why_choose_title}
          desc={homeData.why_choose_tagline}
        />
        <div className="row justify-content-end">
          <div className="col-md-12">
            {homeData?.list?.length ? (
              <Slider {...choose_settings}>
                {homeData?.list?.map((ele, i) => {
                  return (
                    <div>
                      <div className="choose_us" key={ele?._id}>
                        <div className="icon">
                          <img
                            src={
                              ele?.icon
                                ? `${IMAGE_BASE_URL}home/${ele?.icon}`
                                : ""
                            }
                            alt={ele?.icon_alt}
                          />
                          <h4>{ele?.name}</h4>
                        </div>
                        <p>{ele?.description}</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <section className="skyward__testimonials">
        <div className="container">
          <Title
            color={"#fff"}
            title={homeData.review_title}
            desc={homeData.review_tagline}
          />
          <div className="row justify-content-center">
            <div className="col-md-10 col-tab-12">
              <Slider {...testimonial_settings}>
                {nestedTestimonial?.map((group, i) => (
                  <div key={i} className="slide-item">
                    <div className="testimonials_grid">
                      {group.map((ele, i) => (
                        <div className="testimonials" key={i}>
                          <div className="testimonial_content">
                            <img src="assets/img/icons/quote.svg" alt="" />
                            {/* <h4>{ele?.review}</h4> */}
                            <h4 className={readMoreState[i] ? "readmore" : ""}>
                              {readMoreState[i]
                                ? ele?.review
                                : ele?.review.slice(0, 180) +
                                  (ele?.review.length > 180 ? "..." : "")}
                            </h4>
                          </div>
                          <div className="read__more">
                            {ele?.review.length > 180 && (
                              <p>
                                <a onClick={() => handleReadMore(i)}>
                                  Read {readMoreState[i] ? "Less" : "More"}
                                </a>
                              </p>
                            )}
                          </div>
                          <div className="author">
                            <ul>
                              {Array.from({ length: Number(ele?.rating) }).map(
                                (_, index) => (
                                  <li>
                                    <img
                                      src="assets/img/icons/rate.svg"
                                      alt=""
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                            <div className="name">
                              {ele?.profile_image ? (
                                <img
                                  src={`${IMAGE_BASE_URL}reviews/${ele?.profile_image}`}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={"/assets/img/logo.svg"}
                                  style={{
                                    objectFit: "contain",
                                    border: "1px solid #f1f1f1",
                                  }}
                                  alt=""
                                />
                              )}
                              <div className="content">
                                <h4>{ele?.name}</h4>
                                {/* <p>Customer</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__curated">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="packageGrid">
                <img
                  className="main w-100"
                  src={
                    homeData.curated_description
                      ? `${IMAGE_BASE_URL}home/${homeData.curated_image}`
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="packageGrid">
                <div className="content">
                  <h2 data-aos="fade-up" data-aos-duration={1000}>
                    {homeData.curated_title}
                  </h2>
                  <p data-aos="fade-up" data-aos-duration={2000}>
                    {homeData.curated_description}
                  </p>
                  <button
                    className="btn btn-primary"
                    data-aos="fade-up"
                    data-aos-duration={3000}
                    onClick={() => navigate("/guided-tours")}
                  >
                    EXPLORE{" "}
                    <img src="assets/img/icons/button-arrow.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {homeData?.blogDetails?.length ? (
        <section className="skyward__blogs">
          <div className="container">
            <Title title={homeData.blog_title} desc={homeData.blog_tagline} />
            {homeData?.blogDetails?.length ? (
              <div className="row">
                <div
                  className={
                    homeData?.blogDetails?.length == 1
                      ? "col-md-12"
                      : "col-md-6"
                  }
                >
                  <div
                    className="blog"
                    onClick={() =>
                      navigate(
                        `/blogs-details/${homeData?.blogDetails?.[0]?.handle_key}`
                      )
                    }
                  >
                    <img
                      src={
                        homeData.blogDetails?.[0].thumbnail
                          ? `${IMAGE_BASE_URL}${"blog"}/${
                              homeData?.blogDetails?.[0]?.thumbnail
                            }`
                          : ""
                      }
                      alt={homeData?.blogDetails?.[0]?.thumbnail_alt}
                    />
                    <h2>{homeData?.blogDetails?.[0]?.title}</h2>
                  </div>
                </div>
                <div className="col-md-6">
                  {homeData?.blogDetails?.slice(1, 3)?.map((ele, i) => {
                    return (
                      <div
                        className={`blog ${
                          homeData?.blogDetails?.slice(1, 3)?.length == 1
                            ? ""
                            : "sm-style"
                        }`}
                        onClick={() =>
                          navigate(`/blogs-details/${ele?.handle_key}`)
                        }
                      >
                        <img
                          src={
                            ele.thumbnail
                              ? `${IMAGE_BASE_URL}${"blog"}/${ele.thumbnail}`
                              : ""
                          }
                          alt={ele?.thumbnail_alt}
                        />
                        <h2>{ele?.title}</h2>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
            {homeData?.total_blogs > 3 && (
              <div className="button_center">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/blogs")}
                >
                  VIEW MORE{" "}
                  <img src="assets/img/icons/button-arrow.svg" alt="" />
                </button>
              </div>
            )}
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
