import { Form, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  book_now_loader,
  currencyValue,
  discount_cost,
  package_common_details,
  package_side_payment_details,
  promo_code,
  promo_code_applied,
  promo_loader,
  total_cost,
  travelerData,
  updateRedux,
} from "../../../../redux/commonReducer";
import {
  calculateDateDuration,
  CurrencyConversion,
} from "../../../../utils/helpers";
import { useEffect } from "react";
import { getFlightCostDetails } from "../../../../redux/commonActionCreator";
import moment from "moment";

const PackageDetailsSection = ({
  onBookingHandler,
  onPromoHandler,
  onRemovePromoHandler,
}) => {
  const dispatch = useDispatch();

  const details = JSON.parse(localStorage.getItem("package_details"));
  const flightBooking = localStorage.getItem("flight_booking");
  const localData = JSON.parse(localStorage.getItem("searchData"));
  const localGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const verifiedOtp = localStorage.getItem("otp_verified") == "true" ? true : false;
  const selectedFlight = JSON.parse(localStorage.getItem("selected_flight"));
  const package_page_details = useSelector(package_common_details)
  const promotion_code = useSelector(promo_code);
  const data = useSelector(travelerData);
  const discount = useSelector(discount_cost);
  const total_cost_after_discount = useSelector(total_cost);
  const promotionApplied = useSelector(promo_code_applied);
  const promoLoader = useSelector(promo_loader);
  const bookNowLoader = useSelector(book_now_loader);
  const selectedCurrency = useSelector(currencyValue);
  const sidePaymentDetails = useSelector(package_side_payment_details);

  useEffect(() => {
    let raw = {
      id: details?.handle_key,
      departure_id: localData?.departure?.[0]?._id ?? "",
      start_date: localData?.start_date
        ? moment(localData?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: localData?.end_date
        ? moment(localData?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: localGuestData?.find((ele) => ele.key === "adults")?.count,
        age_5_12: localGuestData?.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: localGuestData?.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: localGuestData?.find((ele) => ele.key === "age_less_2")
          ?.count,
      },
      selectedFlight: selectedFlight,
    };
    if (selectedFlight) {
      dispatch(getFlightCostDetails(raw));
    }
  }, []);


  return (
    <>
      <div className="col-md-4">
        <div className="card pricing_details">
          <div className="card-body">
            <div className="package__details bb">
              <h4>PACKAGE</h4>
              <p>
                {CurrencyConversion(
                  selectedCurrency,
                  sidePaymentDetails?.flight?.PackageAdult_cost ??
                  details?.adult_cost
                ) ?? details?.adult_cost}
                <span>
                  {" "}
                  {selectedCurrency?.currency_code ?? "USD"} X Per Person
                </span>
              </p>
              <span>
                total of{" "}
                {CurrencyConversion(
                  selectedCurrency,
                  sidePaymentDetails?.flight?.PackageTotal_cost ??
                  details?.estimate
                ) ?? details?.estimate}{" "}
                {selectedCurrency?.currency_code ?? "USD"}
              </span>
            </div>
            {flightBooking === "true" && selectedFlight ? (
              <div className="package__details bb">
                <h4>AIRFARE</h4>
                <p>
                  {CurrencyConversion(
                    selectedCurrency,
                    sidePaymentDetails?.flight?.perPerson ?? 0
                  )}
                  <span>
                    {" "}
                    {selectedCurrency?.currency_code ?? "USD"} X Per Person
                  </span>
                </p>
                <span>
                  total of{" "}
                  {CurrencyConversion(
                    selectedCurrency,
                    sidePaymentDetails?.flight?.totalaircost ?? 0
                  )}
                  {selectedCurrency?.currency_code ?? "USD"}
                </span>
              </div>
            ) : (
              <></>
            )}

            {promotionApplied ? (
              <div className="discount bb">
                <h6>Discount</h6>
                <p>{discount} USD</p>
              </div>
            ) : (
              <></>
            )}

            <div className="details bb">
              <h4>PACKAGE DETAILS</h4>
              <div className="item">
                <h6>Adults</h6>
                <p>
                  {localGuestData?.find((ele) => ele?.key == "adults")?.count >
                    0
                    ? localGuestData?.find((ele) => ele?.key == "adults")?.count
                    : 1}
                </p>
              </div>
              <div className="item">
                <h6>Children</h6>
                <p>
                  {localGuestData
                    ?.filter((ele) => ele?.key !== "adults")
                    ?.reduce((sum, person) => sum + person.count, 0) ?? 0}
                </p>
              </div>
              <div className="item">
                <h6>Duration</h6>
                {/* <p>{details?.duration} Days</p> */}
                <p>
                  {calculateDateDuration(
                    moment(localData?.start_date).format("YYYY-MM-DD"),
                    moment(localData?.end_date).format("YYYY-MM-DD")
                  ) ?? details?.duration}{" "}
                  {details?.duration === 1 ? "Day" : "Days"}
                </p>

              </div>
            </div>
            {verifiedOtp && (
              <div className="promo_code">
                <h4>PROMOTIONAL code</h4>
                {!promotionApplied ? (
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Gift card or promo code"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => {
                        dispatch(
                          updateRedux({
                            key: "promo_code",
                            value: e?.target?.value,
                          })
                        );
                      }}
                      value={promotion_code}
                    />
                    <div
                      className="btn btn-secondary"
                      aria-disabled={
                        (promotion_code == "" && data.email == "") ||
                        promoLoader
                      }
                      onClick={() =>
                        (promotion_code == "" && data.email == "") ||
                          promoLoader
                          ? {}
                          : onPromoHandler()
                      }
                    >
                      Apply
                      {promoLoader ? <Spinner animation="border" /> : ""}
                    </div>
                  </InputGroup>
                ) : (
                  <>
                    {promotion_code ? (
                      <div className="remove__promoCode">
                        <div className="tick">
                          <img src="/assets/img/icons/circle-tick.svg" alt="" />
                          <h5>
                            {promotion_code} <span>Applied</span>
                          </h5>
                        </div>
                        <h5
                          className="remove mousePointer"
                          onClick={() => onRemovePromoHandler()}
                        >
                          Remove
                        </h5>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="grand__total bb">
              <h4>
                GRAND TOTAL{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-total">
                      {package_page_details?.tool_tip}
                    </Tooltip>
                  }
                >
                  <img src="/assets/img/icons/total.svg" height={20} alt="Grand Total Icon" />
                </OverlayTrigger>
              </h4>
              <h2>
                {CurrencyConversion(
                  selectedCurrency,
                  sidePaymentDetails
                    ? Number(sidePaymentDetails?.flight?.PackageAdult_cost) +
                    Number(sidePaymentDetails?.flight?.perPerson) ??
                    details?.adult_cost
                    : details?.adult_cost
                ) ?? details?.adult_cost}
                <span>
                  <strong>{selectedCurrency?.currency_code ?? "USD"}</strong>{" "}
                  <small>X Per Person</small>
                </span>
              </h2>
              <p>
                {/* grandTotalWithAirCost */}
                Total of{" "}
                {CurrencyConversion(
                  selectedCurrency,
                  total_cost_after_discount != ""
                    ? total_cost_after_discount
                    : sidePaymentDetails?.flight?.grandTotalWithAirCost ??
                    details?.estimate
                )
                  ? CurrencyConversion(
                    selectedCurrency,
                    total_cost_after_discount != ""
                      ? total_cost_after_discount
                      : sidePaymentDetails?.flight?.grandTotalWithAirCost ??
                      details?.estimate
                  )
                  : total_cost_after_discount != ""
                    ? total_cost_after_discount
                    : sidePaymentDetails?.flight?.grandTotalWithAirCost ??
                    details?.estimate}{" "}
                {selectedCurrency?.currency_code ?? "USD"}{" "}
                <span>( Approx.)</span>
              </p>
            </div>

            <div className="reserve bb">
              <img src="/assets/img/icons/reserve.svg" alt="" />
              <div>
                {" "}
                Reserve now for <br />
                just <strong>${details?.token_payment}</strong> and pay the
                rest later!
              </div>
            </div>

            <div className="book__now bb">
              <div className="price">
                <h3>
                  {CurrencyConversion(
                    selectedCurrency,
                    details?.token_payment ?? 0
                  )}{" "}
                  <small>{selectedCurrency?.currency_code ?? "USD"}</small>
                </h3>
              </div>
              <div
                className={`link ${verifiedOtp ? "bookNowColor" : "linkColor"}`}
                aria-disabled={!verifiedOtp || bookNowLoader}
                onClick={() => (verifiedOtp ? onBookingHandler() : {})}
              >
                {bookNowLoader ? (
                  <>
                    book now <Spinner animation="border" />
                  </>
                ) : (
                  <>
                    book now
                    <img src="/assets/img/icons/button-arrow.svg" alt="" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="footer_links">
              <Link
                to="/booking-process"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking Process
              </Link>
              <Link
                to="/cancel-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Cancellation Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PackageDetailsSection;
