import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  general_details,
  package_common_details,
  package_common_path,
  package_loader,
  search_data,
  searchKeyword,
  updateRedux,
} from "../../redux/commonReducer";
import PackageList from "./PackageList";
import {
  commonSearch,
  packageCommonData,
} from "../../redux/commonActionCreator";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { Spinner } from "react-bootstrap";

export default function CommonSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loader = useSelector(package_loader);
  const packageCommonDetails = useSelector(package_common_details);
  const packageCommonPath = useSelector(package_common_path);
  const searchResult = useSelector(search_data);
  const keyword = useSelector(searchKeyword);
  const generalDetails = useSelector(general_details);

  const { packages, activities, attractions, blogs, pagination } = searchResult;

  const [data, setData] = useState({
    page: 0,
    perPage: 8,
  });
  const [searchErr, setSearchErr] = useState("");

  useEffect(() => {
    getSearchDataHandler(data);
    dispatch(packageCommonData());
  }, []);

  const getSearchDataHandler = (filter) => {
    let rawData = {
      page: filter.page,
      perPage: filter.perPage,
      keyword: keyword,
    };
    dispatch(commonSearch(rawData));
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setData({
      ...temp,
    });
    getSearchDataHandler(temp);
  };

  const onSearchHandler = () => {
    if (keyword?.trim()?.length) {
      let raw = {
        keyword: keyword,
      };
      dispatch(commonSearch(raw));
    } else {
      setSearchErr("Search keyword is required");
    }
  };

  const onCancelHandler = () => {
    dispatch(
      updateRedux({
        key: "searchKeyword",
        value: "",
      })
    );
    dispatch(commonSearch());
  };

  return (
    <>
      <section className="skyward__selfDrive pt-0">
        <img
          src={`${IMAGE_BASE_URL}${packageCommonPath}/${packageCommonDetails?.banner_image}`}
          alt={packageCommonDetails?.banner_image_alt}
          className="w-100 main"
        />
        <div className="search__contents">
          <h1>{generalDetails?.search_title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: generalDetails?.search_tagline,
            }}
          ></p>
          <div className="search__area">
            <div
              className="search__home"
              style={{ gridTemplateColumns: "70% 30%" }}
            >
              <div className="grid">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Here.."
                  onChange={(e) =>
                    dispatch(
                      updateRedux({
                        key: "searchKeyword",
                        value: e.target.value,
                      })
                    )
                  }
                  value={keyword}
                />
              </div>
              <div className="grid">
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-primary search"
                    onClick={() => onSearchHandler()}
                    disabled={loader}
                    style={{ textWrap: "nowrap" }}
                  >
                    Search
                    {loader && <Spinner animation="border" />}
                  </button>
                  <button
                    className="btn btn-primary search"
                    onClick={() => onCancelHandler()}
                    disabled={loader}
                    style={{ textWrap: "nowrap" }}
                  >
                    Cancel
                    {loader && <Spinner animation="border" />}
                  </button>
                </div>

                <small id="error" className="error"></small>
              </div>
            </div>
            <p className="mb-1" style={{ color: "red" }}>
              {searchErr}
            </p>
          </div>
        </div>
      </section>
      {!packages?.length &&
      !activities?.length &&
      !attractions?.length &&
      !blogs?.length ? (
        <section className="search__results pt-5">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <p>No Data Found</p>
            </div>
          </div>
        </section>
      ) : (
        <>
          {packages?.length ? (
            <section className="search__results pt-5">
              <div className="container">
                <div className="row">
                  <h2 className="title mb-0">Packages</h2>
                  <PackageList packageData={packages} />
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          {activities?.length ? (
            <section className="search__results">
              <div className="container">
                <div className="attraction">
                  <div className="container">
                    <div className="header">
                      <h2 className="title">Activities</h2>
                    </div>
                    <div className="row">
                      {activities?.map((ele, i) => {
                        return (
                          <div className="col-md-3" key={i}>
                            <Link to={`/activity-details/${ele?.handle_key}`}>
                              <div className="atraction">
                                <div className="image">
                                  <img
                                    src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                    alt={ele.image_alt}
                                    className="w-100"
                                  />
                                </div>
                                <div className="contents">
                                  <h3>{ele?.title}</h3>
                                  <p>
                                    <img
                                      src="/assets/img/icons/clock.svg"
                                      height={17}
                                      alt=""
                                    />
                                    Duration {ele?.duration} hours
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          {attractions?.length ? (
            <section className="search__results">
              <div className="container">
                <div className="attraction">
                  <div className="container">
                    <div className="header">
                      <h2 className="title">ATTRACTIONS</h2>
                    </div>
                    <div className="row">
                      {attractions?.map((ele, i) => {
                        return (
                          <div className="col-md-3" key={i}>
                            <Link to={`/attraction-details/${ele?.handle_key}`}>
                              <div className="atraction">
                                <div className="image">
                                  <img
                                    src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                                    alt={ele.image_alt}
                                    className="w-100"
                                  />
                                </div>
                                <div className="contents">
                                  <h3>{ele?.title}</h3>
                                  <p
                                    className="attract"
                                    dangerouslySetInnerHTML={{
                                      __html: ele?.short_description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          {blogs?.length ? (
            <section className="search__results">
              <div className="container">
                <div className="blogs">
                  <div className="container">
                    <h2 className="title">Blogs</h2>
                    <div className="row">
                      {blogs?.map((ele, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div
                              className="blogGrid"
                              onClick={() =>
                                navigate(`/blogs-details/${ele?.handle_key}`)
                              }
                            >
                              <img
                                src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.thumbnail}`}
                                alt={ele.image_alt}
                                className="w-100"
                              />
                              <h4>{ele?.title}</h4>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
