

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const CookieConsentBanner = ({ show ,err, setErr}) => {

    const [cookies, setCookie] = useCookies(["userConsent"]);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        if (!cookies.userConsent) {
            setShowBanner(true);
        }
    }, [cookies]);

    useEffect(() => {
        if (show) {
            setShowBanner(true);
        }
    }, [show]);

    useEffect(() => {
        if (err) {
          const timer = setTimeout(() => {
            setErr((prev) => ({ ...prev, cookies_consent: "" }));
          }, 1000);
          return () => clearTimeout(timer);
        }
      }, [err]);


    const handleAccept = () => {
        setCookie("userConsent", "accepted", { path: "/", maxAge: 7 * 24 * 60 * 60 });
        setShowBanner(false);
    };

    const handleReject = () => {
        setCookie("userConsent", "rejected", { path: "/", maxAge: 7 * 24 * 60 * 60 });
        setShowBanner(false);

    };


    return (
        <>
            {showBanner && (
                <div className={`cookie-consent ${err ? "jerk-shake" : ""}`} >
                    <p style={{color: err ? "#DC1E35" : "white"}}>
                        We use cookies to enhance your browsing experience, serve personalised ads or content,
                        and analyse our traffic. By clicking "Accept All", you consent to our use of cookies.{" "}
                        {/* Uncomment the link below if needed */}
                        {/* <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
                  Cookie Policy
                </a> */}
                    </p>
                    <div className="buttons">
                        <button onClick={handleAccept}>Accept All</button>
                        {!show && (
                            <button onClick={handleReject}>Reject All</button>
                        )}

                    </div>
                </div>
            )}
        </>
    );

};

export default CookieConsentBanner;

