import { Helmet } from "react-helmet-async";
import { CURRENT_URL } from "../../constants/configuration";
import { useLocation } from "react-router-dom";

const MetaHeader = ({ 
  title, 
  description, 
  keywords,
  ogType = "website"
}) => {
  const formattedTitle = title 
    ? `${title} | Skyward Xperia Website` 
    : 'Skyward Xperia Website';

  const location = useLocation()

const canonicalUrl = `${CURRENT_URL}${location?.pathname}`
const ogImage = `${CURRENT_URL}/assets/img/about-package.jpg`



  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{formattedTitle}</title>
      <meta name="description" content={description || 'Description for Skyward Xperia Website'} />
      <meta name="keywords" content={keywords || ''} />
      
      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description || 'Description for Skyward Xperia Website'} />
      <meta property="og:type" content={ogType} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={formattedTitle} />
      <meta name="twitter:description" content={description || 'Description for Skyward Xperia Website'} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      
      {/* Additional Important Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>
  );
};

export default MetaHeader;