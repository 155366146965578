import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { curated } from "../../../../components/web/config";
import { Wizard, useWizard, BasicFooter } from "rhf-wizard";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Navigation from "./Navigation";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRoundTripFlights, packageCommonData } from "../../../../redux/commonActionCreator";
import {
  booking_completed,
  flight_list,
  searchData,
  searchGuestData,
  selected_flight,
  updateRedux,
} from "../../../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../../../constants/configuration";
import PackageExpect from "./PackageExpect";

export default function PackageBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localData = JSON.parse(localStorage.getItem("searchData"));
  const LocalGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const flightBooking = localStorage.getItem("flight_booking");
  const details = JSON.parse(localStorage.getItem("package_details"));
  const path = localStorage.getItem("package_path") ?? "package";
  
  const data = useSelector(searchData);
  const guestData = useSelector(searchGuestData);
  const flightList = useSelector(flight_list);
  const selectedFlight = useSelector(selected_flight);
  const bookingCompleted = useSelector(booking_completed);

  const [currentStep, setCurrentStep] = useState("0");

  useEffect(() => {
    if (localData && LocalGuestData) {
      dispatch(
        updateRedux({
          key: "searchData",
          value: localData,
        })
      );
      dispatch(
        updateRedux({
          key: "searchGuestData",
          value: LocalGuestData,
        })
      );
      if (flightBooking == "true") {
        searchFlight(localData, LocalGuestData);
      }
    }
    dispatch(packageCommonData());
  }, []);

  const searchFlight = (dataValue, guestValue) => {
    let raw = {
      origin: dataValue?.departure?.[0]?.airport_code ?? "",
      trip_start: dataValue?.start_date
        ? moment(dataValue?.start_date).format("YYYY-MM-DD")
        : "",
      trip_return: dataValue?.end_date
        ? moment(dataValue?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestValue?.find((ele) => ele.key === "adults")?.count,
        age_5_12: guestValue?.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: guestValue?.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: guestValue?.find((ele) => ele.key === "age_less_2")?.count,
      },
      no_of_travellers: guestValue?.reduce(
        (sum, person) => sum + person?.count,
        0
      ),
    };
    dispatch(getRoundTripFlights(raw));
    localStorage.setItem("searchData", JSON.stringify(dataValue));
    localStorage.setItem("searchGuestData", JSON.stringify(guestData));
  };

  const steps = [
    {
      id: "0",
      component: <Step2 />,
      hidePrevious: true,
    },
    {
      id: "1",
      component: <Step3 />,
    },
    {
      id: "2",
      component: <Step4 />,
      hideNext: true,
    },
  ];

  let filteredStep = useMemo(() => {
    if (flightBooking === "true") return steps;
    else return steps?.filter((el) => el?.id !== "0");
  }, [flightBooking]);

  const onSearch = () => {
    if (flightBooking === "true") {
      searchFlight(data, guestData);
    }
  };

  return (
    <section className="skyward__package_details p-0">
      <div className="slider__content">
        <div className="content">
          <h1>{details?.package_name}</h1>
          {/* <div className="btns">
            <button className="btn btn-secondary details">
              View on map{" "}
              <img src="/assets/img/icons/button-arrow.svg" alt="" />
            </button>
            <button className="btn btn-secondary details">
              View Gallery{" "}
              <img src="/assets/img/icons/button-arrow.svg" alt="" />
            </button>
          </div> */}
        </div>
        <Slider {...curated}>
          {details?.banner_image?.map((ele, i) => {
            return ele?.banner_type == "image" ? (
              <div className="image_slide">
                <img
                  src={`${IMAGE_BASE_URL}${path}/${ele?.banner_item}`}
                  alt={ele.banner_alt}
                  className="w-100 main"
                />
              </div>
            ) : (
              <div className="image_slide">
                <video
                  muted
                  autoPlay
                  playsInline
                  loop
                  className="masked-video"
                  src={`${IMAGE_BASE_URL}${path}/${ele.banner_item}`}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="booking__wizard">
        <div className="container-fluid p-0">
          <Wizard
            header={<Navigation onSearch={onSearch} />}
            steps={filteredStep}
            onStepChanged={(fromStep, toStep) => {
              setCurrentStep(toStep.id); // Update the current step state
            }}
            onCompleted={(values) => {
              alert("wizard completed");
            }}
          />
        </div>
      </div>

      {!bookingCompleted ? <PackageExpect   /> : <></>}
    </section>
  );
}

